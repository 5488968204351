@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Mechsuit";
    src: url("/fonts/mechsuit/Mechsuit.otf");
    font-display: swap;
  }

  @font-face {
    font-family: "GothamBook";
    font-weight: "300";
    src: url("/fonts/gotham/GothamBook.ttf");
    font-display: swap;
  }

  @font-face {
    font-family: "Gotham";
    font-weight: "500";
    src: url("/fonts/gotham/GothamMedium.ttf");
    font-display: swap;
  }

  @font-face {
    font-family: "GothamBold";
    font-weight: "bold";
    src: url("/fonts/gotham/GothamBold.ttf");
    font-display: swap;
  }

  @font-face {
    font-family: "CAMechanoCondensed";
    font-stretch: "condensed";
    src: url("/fonts/ca_mechano/CAMechano_v1010-Condensed.woff2");
    font-display: swap;
  }

  @font-face {
    font-family: "CAMechano";
    font-stretch: "normal";
    src: url("/fonts/ca_mechano/CAMechano_v1010-Regular.woff2");
    font-display: swap;
  }

  @font-face {
    font-family: "CAMechanoWide";
    font-stretch: "expanded";
    src: url("/fonts/ca_mechano/CAMechano_v1010-Wide.woff2");
    font-display: swap;
  }
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

* {
  box-sizing: border-box;
}

.slick-track {
  display: flex !important;
}

.slick-slide {
  height: inherit !important;
}

@media screen and (max-width: 400px) {
  .carousel .thumbs {
    display: none;
  }
}

/*Debug overflow */
/* * {
  outline: 1px solid #f00 !important; 
} */
